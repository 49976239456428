<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="BriefcaseIcon" size="19"/>
          <h4 class="mb-0 ml-50">Masraf Formu Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Belge Tarihi" rules="required">
              <b-form-group label="Belge Tarihi" :state="getValidationState(validationContext)">
                <flat-pickr v-model="dataInfo.docDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Belge Numarası" rules="required">
              <b-form-group label="Belge Numarası">
                <b-form-input trim placeholder="Belge Numarası" v-model="dataInfo.ficheNo" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Masraf Tipi" rules="required">
              <b-form-group label="Masraf Tipi" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.expenseTypeId" :options="expenseTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Müşteri">
              <b-form-group label="Müşteri" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.customerId" :options="customerOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Toplam" rules="required">
              <b-form-group label="Toplam">
                <b-form-input trim placeholder="Toplam" v-model="dataInfo.total" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="KDV" rules="required">
              <b-form-group label="KDV">
                <b-form-input trim placeholder="KDV" v-model="dataInfo.totalVat" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Genel Toplam" rules="required">
              <b-form-group label="Genel Toplam">
                <b-form-input trim placeholder="Genel Toplam" v-model="dataInfo.totalNet" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Para Birimi" rules="required">
              <b-form-group label="Para Birimi" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.currencyTypeId" :options="currencyTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Ödeme Şekli" rules="required">
              <b-form-group label="Ödeme Şekli" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.paymentTypeId" :options="paymentTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <action-buttons :back-route="'expense-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/expense/store"
import definitionModule from "@/views/system/definition/store"
import customerModule from "@/views/definition/customer/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    flatPickr,

    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_CUSTOMER_MODULE_NAME = 'store-customer'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_CUSTOMER_MODULE_NAME, customerModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_CUSTOMER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      docDate: null,
      ficheNo: '',
      expenseTypeId: null,
      paymentTypeId: null,
      total: null,
      totalVat: null,
      totalNet: null,
      currencyTypeId: null,
      description: '',
      customerId: null,
      userId: null,
      image: null,
      imageBase64: null,
    })

    const customerOptions = ref([])
    const expenseTypeOptions = ref([])
    const currencyTypeOptions = ref([])
    const paymentTypeOptions = ref([])

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'EXPENSE_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        expenseTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.expenseTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'PAYMENT_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        paymentTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.paymentTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'CURRENCY_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        currencyTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.currencyTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-customer/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          customerOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'expense-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'expense-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      expenseTypeOptions,
      customerOptions,
      currencyTypeOptions,
      paymentTypeOptions,

      onSubmit,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
